* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

//import partials
@import "./player";
@import "./song";
@import "./library";
@import "./nav";

h1,
h2,
h3,
h4 {
  color: rgb(112, 112, 112);
}

body {
  font-family: "Raleway", sans-serif;
}

.App {
  transition: all 0.5s ease;
}

.library-active {
  margin-left: 30%;
}
