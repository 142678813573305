.library {
  position: fixed;
  left: 0;
  top: 0;
  width: 20rem;
  height: 100%;
  box-shadow: 2px 2px 50px gray;
  overflow-y: auto;
  transform: translateX(-100%);
  transition: all 0.5s ease;
  background-color: white;
  opacity: 0;
  h2 {
    padding: 2rem;
  }
}

.library-song {
  display: flex;
  align-items: center;
  transition: background 0.3s ease;
  padding: 1rem 2rem;
  cursor: pointer;
  img {
    width: 30%;
  }
  &:hover {
    background-color: rgb(226, 226, 226);
  }
}

.song-description {
  padding-left: 1rem;
  h3 {
    font-size: 1.2rem;
  }
  h4 {
    font-size: 0.8rem;
  }
}

*::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

.selected {
  background-color: rgb(255, 175, 255);
}

.active-library {
  transform: translateX(0%);
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .library {
    width: 100%;
  }
}
